import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import CheckEmail from "../components/CheckEmail";
import SEO from "../components/Seo";
import { AppState } from "../types";

const CheckEmailPage = (): ReactElement => {
  const userEmail = useSelector<AppState, string>(
    (state) => state?.contract?.personalInformation?.values?.emailPrivate || ""
  );

  return (
    <div>
      <SEO title="Verify Email" />
      <CheckEmail email={userEmail} />
    </div>
  );
};

export default CheckEmailPage;
