import React, { ReactElement, useEffect, useState } from "react";
import cn from "classnames";

import { CustomizedDialogs } from "../Footer/Modal";
import { EmailVerificationProps } from "./types";
import { ReactComponent as Logo } from "../../assets/logo-dark.svg";
import { ReactComponent as CheckEmailImage } from "../../assets/check-email.svg";
import { ReactComponent as CheckEmailBackground } from "../../assets/check-email-background.svg";

import styles from "./styles.mod.scss";

const CheckEmail = ({ email }: EmailVerificationProps): ReactElement => {
  const containerCn = cn("container", styles.container);
  const [firstName, setFirstName] = useState("");
  useEffect(() => {
    const firstName = localStorage.getItem("firstName");
    firstName && setFirstName(firstName);
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <CheckEmailBackground height="100%" width="100%" />
      </div>
      <div className={containerCn}>
        <div className="row">
          <div className="col-12">
            <div className={styles.logoContainer}>
              <a className={styles.logoLink} href="/">
                <Logo />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h1 className={styles.title}>
                  Du hast Post von uns bekommen, {firstName}
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className={styles.descriptionContainer}>
                  <p>
                    Wir freuen uns riesig über deine Anmeldung! Bevor wir dich
                    aber als stromee-Kunde begrüßen dürfen, musst du noch kurz
                    deine E-Mail Adresse bestätigen.
                  </p>
                  <p>Schau am besten gleich mal in dein E-Mail Postfach!</p>
                  <p>
                    Du hast keine E-Mail bekommen? Manchmal landet sie leider im
                    Spam. Wenn sie auch da nicht ist, dann schreib uns:&nbsp;
                    <a
                      className={styles.mailLink}
                      href="mailto:hello@stromee.de"
                    >
                      hello@stromee.de
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className={styles.imageContainer}>
                  <CheckEmailImage />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row flex-grow-1 align-items-end">
          <div className="col-12">
            <div className={styles.agrrementsContainer}>
              <CustomizedDialogs
                agreementLinkClassName={styles.agreementLink}
                withoutCopyright
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckEmail;
